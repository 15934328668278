import { ADD_CITY, GET_CITY } from "../types";

const initialState = {
    cities: [],
    test:[]
}

const getCities = (state, payload) => ({
    ...state,
    cities: payload
})


export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_CITY:
            return getCities(state, payload);
        default:
            return state
    }
}