import React from 'react';
import { Divider, Typography, Link } from '@material-ui/core';
import useStyles from './styles';
import './style.css';
import logo from '../../../../assets/Images/Zineflex.webp';

export default function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear().toString();
  return (
    <>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="logo-Image">
              <img src={logo} class="logo" />
              <div class="phargraph">
                Zineflex is a user-friendly and efficient online platform
                dedicated to simplifying the process of booking movie tickets.
                With its intuitive interface and comprehensive features,
                Zineflex aims to provide moviegoers with a hassle-free
                experience when it comes to reserving seats for their favorite
                films.
              </div>
            </div>
            <div class="footer-col">
              <h4>company</h4>
              <ul>
                <li>
                  <a href="#">about us</a>
                </li>
                <li>
                  <a href="#">our services</a>
                </li>
                <li>
                  <a href="#">privacy policy</a>
                </li>
                <li>
                  <a href="#">affiliate program</a>
                </li>
              </ul>
            </div>
            <div class="footer-col">
              <h4>get help</h4>
              <ul>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">shipping</a>
                </li>
                <li>
                  <a href="#">returns</a>
                </li>
                <li>
                  <a href="#">order status</a>
                </li>
                <li>
                  <a href="#">payment options</a>
                </li>
              </ul>
            </div>

            <div class="footer-col">
              <h4>follow us</h4>
              <div class="social-links">
                <a href="#">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i class="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i class="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i class="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={classes.root}>
        <Divider />
        <Typography className={classes.copyright} variant="body1">
          © {currentYear} Zineflex All rights reserved. |
          <Link
            className={classes.matrixmantra}
            href="https://Matrixmantra.lk/"
            target="_blank"
            rel="noopener">
            Web Design & Development by Matrix Mantra
          </Link>
        </Typography>
        <Typography variant="caption">
          {/* Crafted with love |{' '}
      <Link href="http://georgesimos.com/" target="_blank" rel="noopener">
        George Simos
      </Link> */}
        </Typography>
      </div>
    </>
  );
}
