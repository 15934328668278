import axios from 'axios';
import {
  TOGGLE_DIALOG,
  SELECT_SHOWTIMES,
  SELECT_ALL_SHOWTIMES,
  GET_SHOWTIMES,
  GET_SHOWTIME,
  DELETE_SHOWTIME
} from '../types';
import { setAlert } from './alert';

export const toggleDialog = () => ({ type: TOGGLE_DIALOG });

export const selectShowtime = showtime => ({
  type: SELECT_SHOWTIMES,
  payload: showtime
});

export const selectAllShowtimes = () => ({ type: SELECT_ALL_SHOWTIMES });

export const getShowtimes = (isAdmin) => async dispatch => {
  if (isAdmin) {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/shows?fields=id,movieId,cinemaId,startTime,priceForBalconySeat,priceForNormalSeat,isDraft,startDate,endDate,seats,createdAt,updatedAt&draft=true`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    await axios.get(url, { headers })
      .then((res) => {
        dispatch({ type: GET_SHOWTIMES, payload: res.data.data });
      }).catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      })
  }
  else {
    const url = `${process.env.REACT_APP_BE_URL}/shows?fields=id,movieId,cinemaId,startTime,priceForBalconySeat,priceForNormalSeat,startDate,endDate,seats,createdAt,updatedAt`;
    const headers = {
      'Content-Type': 'application/json',
    }
    await axios.get(url, { headers })
      .then((res) => {
        dispatch({ type: GET_SHOWTIMES, payload: res.data.data });
      }).catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      })
  }
};

export const getShowtime = (id, isAdmin) => async dispatch => {
  if (isAdmin) {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/shows/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    await axios.get(url, { headers })
      .then((res) => {
        dispatch({ type: GET_SHOWTIME, payload: res.data.data });
      }).catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      })
  } else {
    const url = `${process.env.REACT_APP_BE_URL}/shows/${id}`;
    const headers = {
      'Content-Type': 'application/json',
    }
    await axios.get(url, { headers })
      .then((res) => {
        dispatch({ type: GET_SHOWTIME, payload: res.data.data });
      }).catch((error) => {
        dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      })
  }
};

export const addShowtime = showtime => async dispatch => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_BE_URL}/shows/createshow`;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  await axios.post(url, {
    movieId: showtime.movieId,
    cinemaId: showtime.cinemaId,
    startTime: showtime.startTime,
    priceForBalconySeat: parseFloat(showtime.priceForBalconySeat),
    priceForNormalSeat: parseFloat(showtime.priceForNormalSeat),
    isDraft: showtime.isDraft,
    startDate: showtime.startDate,
    endDate: showtime.endDate,
    seats: showtime.seats,
  }, { headers })
    .then((res) => {
      dispatch(setAlert('Showtime Created', 'success', 5000));
      dispatch(getShowtimes(true));
      return { status: 'success', message: 'Showtime Created' };
    }).catch((error) => {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      return {
        status: 'error',
        message: ' Cinema have not been saved, try again.'
      };
    })
}

export const updateShowtime = (showtime, id) => async dispatch => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_BE_URL}/shows/updateshow/${id}`;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  try {
    axios.put(url, {
      movieId: showtime.movieId,
      cinemaId: showtime.cinemaId,
      startTime: showtime.startTime,
      priceForBalconySeat: parseFloat(showtime.priceForBalconySeat),
      priceForNormalSeat: parseFloat(showtime.priceForNormalSeat),
      isDraft: showtime.isDraft,
      startDate: showtime.startDate,
      endDate: showtime.endDate,
      seats: showtime.seats,
    }, { headers })
      .then((res) => {
        dispatch(setAlert(res?.data?.message, 'success', 5000));
        return { status: 'success', message: 'Showtime updated' };
      })
      .catch((error) => {
        dispatch(setAlert(error?.response?.data?.message, 'error', 5000));
        return {
          status: 'error',
          message: ' show have not been updated, try again.'
        };
      })
  } catch (error) {
    dispatch(setAlert(error?.message, 'error', 5000));
    return {
      status: 'error',
      message: ' Cinema have not been saved, try again.'
    };
  }
};

export const deleteShowtime = id => async dispatch => {
  const token = localStorage.getItem('jwtToken');
  const url = `${process.env.REACT_APP_BE_URL}/shows/deleteshow/${id}`;
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  }
  axios.delete(url, { headers })
    .then((res) => {
      dispatch(getShowtimes(true));
      dispatch({ type: DELETE_SHOWTIME, payload: id });
      dispatch(setAlert('Showtime Deleted', 'success', 5000));
      return { status: 'success', message: 'Showtime Removed' };
    })
    .catch((error) => {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
      return {
        status: 'error',
        message: ' Showtime have not been deleted, try again.'
      };
    })
};
