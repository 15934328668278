import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from '../types';
import { setAlert } from './alert';
import { setAuthHeaders, setUser, removeUser, isLoggedIn } from '../../utils';
import axios from 'axios';

export const uploadImage = (id, image) => async (dispatch) => {
  try {
    const data = new FormData();
    data.append('file', image);
    const url = `${process.env.REACT_APP_BE_URL}/users/photo/${id}`;
    const response = await fetch(url, {
      method: 'POST',
      body: data
    });
    const responseData = await response.json();
    if (response.ok) {
      dispatch(setAlert('Image Uploaded', 'success', 5000));
    }
    if (responseData.error) {
      dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
    }
  } catch (error) {
    dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
  }
};

// Login user
export const login = (emailOrPhone, password, redirect) => async (dispatch) => {
    const url = `${process.env.REACT_APP_BE_URL}/users/login`;
    await axios.post(url,{ emailOrPhone: emailOrPhone, password: password })
      .then((response) => {
        setAuthHeaders()
        const { data: user } = response;
        user && setUser(user);
        dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        dispatch({ type: USER_LOADED, payload: response });
        dispatch(setAlert(`Welcome ${user.fullName}`, 'success', 5000));
        response?.data?.data?.permissions ? redirect('/admin/dashboard') : redirect('/');
      }).catch((error) => {
        dispatch({ type: LOGIN_FAIL });
        dispatch(setAlert(error?.message, 'error', 5000));
      })
  };

  export const facebookLogin = (e) => async (dispatch) => {
    try {
      const { email, userID, name } = e;
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, userID, name })
      };
      const url = `${process.env.REACT_APP_BE_URL}/users/login/facebook`;
      const response = await fetch(url, options);
      const responseData = await response.json();

      if (response.ok) {
        const { user } = responseData;
        user && setUser(user);
        dispatch({ type: LOGIN_SUCCESS, payload: responseData });
        dispatch(setAlert(`Welcome ${user.name}`, 'success', 5000));
      }
      if (responseData.error) {
        dispatch({ type: LOGIN_FAIL });
        dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAIL });
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
    }
  };

  export const googleLogin =
    ({ profileObj }) =>
      async (dispatch) => {
        try {
          const { email, googleId, name } = profileObj;
          const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, googleId, name })
          };
          const url = `${process.env.REACT_APP_BE_URL}/users/login/google`;
          const response = await fetch(url, options);
          const responseData = await response.json();

          if (response.ok) {
            const { user } = responseData;
            user && setUser(user);
            dispatch({ type: LOGIN_SUCCESS, payload: responseData });
            dispatch(setAlert(`Welcome ${user.name}`, 'success', 5000));
          }
          if (responseData.error) {
            dispatch({ type: LOGIN_FAIL });
            dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
          }
        } catch (error) {
          dispatch({ type: LOGIN_FAIL });
          dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
        }
      };

  // Register user
  export const register =
    ({ name: fullName, email, phone, password }) =>
      async (dispatch) => {
        try {
          const url = `${process.env.REACT_APP_BE_URL}/users/signup`;
          const body = { fullName, email, phone, password };
          const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
          });
          const responseData = await response.json();
          if (response.ok) {
            const { data: user } = responseData;
            user && setUser(user);
            // if (image) dispatch(uploadImage(user._id, image)); // Upload image
            dispatch({ type: REGISTER_SUCCESS, payload: responseData });
            dispatch(setAlert('Register Success', 'success', 5000));
          }
          if (responseData.error) {
            dispatch({ type: REGISTER_FAIL });
            dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
          }
        } catch (error) {
          dispatch({ type: REGISTER_FAIL });
          dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
        }
      };

  // Load user
  export const loadUser = () => async (dispatch) => {
    if (!isLoggedIn()) return;
    try {
      const url = `${process.env.REACT_APP_BE_URL}/users/me`;
      const response = await fetch(url, {
        method: 'GET',
        headers: setAuthHeaders()
      });
      const responseData = await response.json();
      if (response.ok) {
        const { user } = responseData;
        user && setUser(user);
        dispatch({ type: USER_LOADED, payload: responseData });
      }
      if (!response.ok) dispatch({ type: AUTH_ERROR });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Logout
  export const logout = () => async (dispatch) => {
    try {
      const refreshToken = localStorage.getItem('jwtRefreshToken');
      const url = `${process.env.REACT_APP_BE_URL}/users/logout`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refreshToken })
      });
      const responseData = await response.json();
      if (response.ok) {
        removeUser();
        dispatch({ type: LOGOUT });
        dispatch(setAlert('LOGOUT Success', 'success', 5000));
      }
      if (responseData.error) {
        dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
      }
    } catch (error) {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
    }
  };

  // Verify otp
  export const verifyOtp = (phone,email,code) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_BE_URL}/verify`;
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone,email, code })
      });
      const responseData = await response.json();
      if (response.ok) {
        // TODO: do login process using needed data
        dispatch(setAlert('Verification Success', 'success', 5000));
        if(responseData?.data?.userData){
          setUser(responseData?.data?.userData);
          dispatch({ type: USER_LOADED, payload: responseData });
        }
        return {isSuccess:true,msg:'Verification Success'}
      }
      if (responseData.error) {
        dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
        return{isSuccess:false,msg:responseData.error?.response?.data?.error?.message}
      }
    } catch (error) {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
    }
  };

  // Resend otp
  export const resendOtp = (phone,email) => async (dispatch) => {
        try {
          const url = `${process.env.REACT_APP_BE_URL}/verify/resend`;
          const response = await fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({phone,email})
          });
          const responseData = await response.json();
          if (response.ok) {
            // dispatch(setAlert('Verification Success', 'success', 5000));
            return {isSuccess:true,msg:'we have re-sent the code'}
          }
          if (responseData.error) {
            dispatch(setAlert(responseData.error?.response?.data?.error?.message, 'error', 5000));
            return{isSuccess:false,msg:responseData.error?.response?.data?.error?.message}
          }
        } catch (error) {
          dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
        }
      };
